@keyframes fadeIn {
  from {opacity: 0;}
}

.loading-indicator {
  position: relative;
  display: inline-block;
  width: 5rem;
  height: 2rem;
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    pointer-events: none;
    @extend %carousel;
  }


  &.overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1000;

    &:after {
      top: 50%;
    }
  }

  &.fill {
    width: 100%;
    height: 100%;
    position: relative;
    &:after {
      top: 50%;
    }
  }
}

%carousel {
  box-shadow: -22px 32px 0 2px currentColor, 0px 32px 0 2px currentColor, 22px 32px 0 2px currentColor, 30px 32px 0 -8px currentColor;
  animation: dotCarousel 0.7s ease-in-out infinite;
}

@keyframes dotCarousel {
  0% {
    box-shadow:-22px 32px 0 -8px currentColor, -22px 32px 0 2px currentColor, 0px 32px 0 2px currentColor, 22px 32px 0 2px currentColor;
  }

  100% {
    box-shadow:-22px 32px 0 2px currentColor, 0px 32px 0 2px currentColor, 22px 32px 0 2px currentColor, 30px 32px 0 -8px currentColor;
  }
}
