#root {
  min-height: 100vh;
  //display: grid;
  //grid-template-rows: auto 1fr auto;
}
.header {
  &.main {
    background: #fafafa;
    box-shadow: 0 -2px 2px 5px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }
}

.layout {
  &.main {
    display: grid;
    padding-top: 64px;
    grid-template-rows: 1fr auto;
    min-height: 100vh;
  }
}

.menu {
  &.core-menu {
    background: #fff;
    position: fixed;
    height: calc(100vh - 64px);
    overflow: auto;
  }
}

.user-options {
  .core-menu & {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

.footer {
  padding: 1rem;
}

/*

.header {
  &.landing {
    background: #fafafa;
    border-bottom: 2px solid #cacaca;
    box-shadow: 0 -5px 2px 10px rgba(0,0,0,0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

  }
}

.layout {
  &.landing {
    display: grid;
    padding-top: 64px;
    grid-template-rows: 1fr auto;
    min-height: 100vh;
  }
}

 */
