@import "loading";

.extensioncard {
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
}
