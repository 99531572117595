.form-element {
  &.submit-button {
    width: 100%;
  }

  &.space-between {
    display: flex;
    justify-content: space-between;
  }
}
