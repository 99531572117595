.user-menu {
  &.container {
    background: #fff;
    width: 14rem;
  }

  &.header {
    border-bottom: 1px solid #c0c0c0;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "avatar name" "avatar email"
  }

  &.avatar {
    grid-area: avatar;
    margin-right: 0.5rem;
    align-self: center;
  }

  &.name {
    grid-area: name;
    padding: 0.25rem;
  }

  &.email {
    padding: 0.25rem;
    grid-area: email;
  }

  &.body {
    padding: 0.5rem;
    list-style: none;

    li {
      padding: 0.25rem 0;
    }
  }

  &.root-avatar {
    cursor: pointer;
  }
}
